import React from 'react';
import axios from 'axios';
import { AvailableRouters } from './AvailableRouters';
import { SelectedProduct } from './SelectedProduct';
import { SelectedRouter } from './SelectedRouter';
import { WaitlistSignUp } from './WaitlistSignUp';
import { rollbar } from '../Rollbar.js'
import { AvailableProducts } from './AvailableProducts';

export class ProductDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            availabilitySummary: null,
            loading: true,
            error: false,
            selectedProduct: null,
            selectedRouterId: -1,
            selectedRouter: null,
            marketCat: '',
            ragStatus: '',
            isPreorder: false
        };

        this.handleProductClick = this.handleProductClick.bind(this);
        this.handleRouterClick = this.handleRouterClick.bind(this);
        this.showRouterSelection = this.showRouterSelection.bind(this);
        this.handleProductReset = this.handleProductReset.bind(this);
        this.handleRouterReset = this.handleRouterReset.bind(this);
        this.getSelectedProduct = this.getSelectedProduct.bind(this);
        this.getSelectedRouter = this.getSelectedRouter.bind(this);
        this.getMarketCat = this.getMarketCat.bind(this);
        this.getRagStatus = this.getRagStatus.bind(this);
        this.isPreorder = this.isPreorder.bind(this);
        this.displayWaitMessage = this.displayWaitMessage.bind(this);
    }

    handleProductClick(newSelectedProductId) {
        var newSelectedProduct = this.getSelectedProduct(newSelectedProductId);
        var routerCount = newSelectedProduct.availableRouters.filter(function(x) { return x.available; }).length;
        this.setState({
            selectedProduct : newSelectedProduct,
        }, function() {
            if (newSelectedProduct !== null) {
                if (process.env.NODE_ENV === "production") {
                    window.gtag('event', 'product_selected', { selected_product: newSelectedProduct.productName });
                    if (routerCount > 0) {
                        window.gtag('event', 'view_item_list', { item_list_id: "available_routers", item_list_name: "Available routers",
                            items: newSelectedProduct.availableRouters.filter(function(x) { return x.available; }).map(x => ({
                                item_id: x.id,
                                item_name: x.name,
                                price: Number(x.price),
                            }))
                        });
                    }
                }
                else {
                    window.gtag('event', 'product_selected', { selected_product: newSelectedProduct.productName, 'debug_mode':true });
                    if (routerCount > 0) {
                        window.gtag('event', 'view_item_list', { item_list_id: "available_routers", item_list_name: "Available routers",
                            items: newSelectedProduct.availableRouters.filter(function(x) { return x.available; }).map(x => ({
                                item_id: x.id,
                                item_name: x.name,
                                price: Number(x.price),
                            })), 'debug_mode': true
                        });
                    }
                }
            }
            if (this.showRouterSelection() === false) {
                this.handleRouterClick(0);
            }
        });
    }

    showRouterSelection() {
        var showSelection = true;
        if (this.props.currentProduct !== undefined) {
            for (let x in this.props.currentProduct.cpeRequirements) {
                if (showSelection === false) break;
                if (this.state.selectedProduct !== null) {
                    for (let y in this.state.selectedProduct.cpeRequirements) {
                        let currentReq = this.props.currentProduct.cpeRequirements[x];
                        let newReq = this.state.selectedProduct.cpeRequirements[y];
                        if (currentReq === newReq) {
                            showSelection = false;
                            break;
                        }
                    }
                }
            }
        }
        return showSelection;
    }

    handleRouterClick(newSelectedRouterId) {
        var newSelectedRouter = null;

        if (newSelectedRouterId > 0) {
            newSelectedRouter = this.getSelectedRouter(newSelectedRouterId);
        }

        if (newSelectedRouter !== null) {
            if (process.env.NODE_ENV === "production") {
                window.gtag('event', 'router_selected', { selected_router: newSelectedRouter.name, price: Number(newSelectedRouter.price),
                    selected_product: this.state.selectedProduct.productName });
            }
            else {
                window.gtag('event', 'router_selected', { selected_router: newSelectedRouter.name, price: Number(newSelectedRouter.price),
                    selected_product: this.state.selectedProduct.productName,
                    'debug_mode':true });
            }
        }

        this.setState({ selectedRouterId: newSelectedRouterId, selectedRouter: newSelectedRouter });

        this.props.onClick(this.state.selectedProduct, newSelectedRouter, true);
    }

    handleProductReset() {
        this.setState({ selectedRouterId: -1, selectedRouter: null, selectedProduct: null });
        this.props.onClick(null, null);
    }

    handleRouterReset() {
        this.setState({ selectedRouterId: -1, selectedRouter: null });
        this.props.onClick(this.state.selectedProduct, null);
    }

    getSelectedProduct(selectedProductId) {
        if (selectedProductId > 0 && this.state.availabilitySummary && this.state.availabilitySummary.length > 0) {
            return this.state.availabilitySummary.find(x => x.productId === selectedProductId);
        }
        return null;
    }

    getMarketCat(availabilitySummary) {
        if (availabilitySummary && availabilitySummary.length > 0) {
            var selectedProduct = availabilitySummary[0];

            if (selectedProduct !== undefined) {
                return selectedProduct.marketCat;
            }
        }
        return '';
    }

    getRagStatus(availabilitySummary) {
        if (availabilitySummary && availabilitySummary.length > 0) {
            var selectedProduct = availabilitySummary[0];

            if (selectedProduct !== undefined) {
                return selectedProduct.rag;
            }
        }
        return '';
    }

    isPreorder(availabilitySummary) {
        if (availabilitySummary && availabilitySummary.length > 0) {
            var selectedProduct = availabilitySummary[0];

            if (selectedProduct !== undefined) {
                return selectedProduct.preorder;
            }
        }
        return '';
    }

    async componentDidMount() {
        if (this.state.loading) {
            setTimeout(this.displayWaitMessage, 10000);
            if (this.props.availabilitySummary === null || this.props.availabilitySummary === undefined) {
                var url = "";
                if (this.props.selectedAddress.openreachAddressRef !== null) {
                    url = process.env.REACT_APP_THIRTEEN_TEN_API + '/availability/openreach?addressKey=' + this.props.selectedAddress.openreachAddressRef 
                    + '&districtCode=' + this.props.selectedAddress.openreachDistrictCode;
                }
                else if (this.props.selectedAddress.uprn !== null && this.props.selectedAddress.uprn !== undefined
                    && this.props.selectedAddress.uprn !== "") {
                    url = process.env.REACT_APP_THIRTEEN_TEN_API + '/availability?uprn=' + this.props.selectedAddress.uprn
                    if (this.props.selectedAddress.udprn !== null && this.props.selectedAddress.udprn !== undefined
                        && this.props.selectedAddress.udprn !== ""
                    ) {
                        url += '&udprn=' + this.props.selectedAddress.udprn;
                    }
                }
                else if (this.props.selectedAddress.udprn !== null && this.props.selectedAddress.udprn !== undefined
                    && this.props.selectedAddress.udprn !== ""
                ) {
                    url = process.env.REACT_APP_THIRTEEN_TEN_API + '/availability?udprn=' + this.props.selectedAddress.udprn;
                }
                await axios.get(url)
                .then(response => {
                    this.setState({
                        availabilitySummary: response.data, 
                        marketCat: this.getMarketCat(response.data),
                        ragStatus: this.getRagStatus(response.data),
                        isPreorder: this.isPreorder(response.data)
                    }, function() {
                        var productCount = this.state.availabilitySummary.length;
                        if (process.env.NODE_ENV === "production") {
                            window.gtag('event', 'address_selected', { address: this.props.selectedAddress.formattedAddress });
                            if (productCount > 0) {
                                window.gtag('event', 'view_item_list', { item_list_id: "available_products", item_list_name: "Available products",
                                    items: response.data.map(x => ({
                                        item_id: x.productId,
                                        item_name: x.productName,
                                        item_category: 'Market ' + x.marketCat,
                                        price: Number(x.monthlyFee),
                                        setup_fee: Number(x.setupFee)
                                    }))
                                });
                            }
                        }
                        else {
                            window.gtag('event', 'address_selected', { address: this.props.selectedAddress.formattedAddress, 'debug_mode':true });
                            if (productCount > 0) {
                                window.gtag('event', 'view_item_list', { item_list_id: "available_products", item_list_name: "Available products",
                                    items: response.data.map(x => ({
                                        item_id: x.productId,
                                        item_name: x.productName,
                                        item_category: 'Market ' + x.marketCat,
                                        price: Number(x.monthlyFee),
                                        setup_fee: Number(x.setupFee)
                                    })), 'debug_mode':true
                                });
                            }
                        }
                    });
                })
                .catch(error => {
                    rollbar.error(error);
                    this.setState({ error: true });
                })
                .finally(() => {
                    this.setState({ loading: false });
                    document.getElementById("productSelection")?.scrollIntoView({ block: 'end',  behavior: 'smooth' });
                });
            }
            else {
                this.setState({
                    availabilitySummary: this.props.availabilitySummary,
                    marketCat: this.getMarketCat(this.props.availabilitySummary),
                    ragStatus: this.getRagStatus(this.props.availabilitySummary),
                    isPreorder: this.isPreorder(this.props.availabilitySummary),
                    loading: false
                });
            }
        }
    }

    displayWaitMessage() {
        var msg = document.getElementById("waitMessage");
        if (msg) {
            msg.classList.remove("is-hidden");
        }
    }

    getSelectedRouter(newSelectedRouterId) {
        if (newSelectedRouterId <= 0) return null;
        return this.state.selectedProduct?.availableRouters.find(({id}) => id === newSelectedRouterId.toString());
    }

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <h1 className="title is-4 my-3">Loading Available Services</h1>
                    <p id="waitMessage" className="my-3 is-hidden">Hang in there...</p>
                    <div className="fa-2x">
                        <i className="fas fa-spinner fa-pulse"></i>
                    </div>
                </div>
            );
        }
        else if (this.state.error) {
            return (
                <div className="block"><p>Sorry, an error occurred while checking availability at the selected address.<br />
                Please <a target="_blank" rel="noreferrer" href="https://1310.io/contact">send us a message</a> or call us on <a href="tel:03333001310">0333 300 1310</a> to discuss.</p></div>
            );
        }
        else if (this.state.availabilitySummary && this.state.availabilitySummary.length > 0) {
            return (
                <div id="productSelection">
                    { 
                        this.state.selectedProduct == null &&
                        <AvailableProducts ragStatus={this.state.ragStatus} availabilitySummary={this.state.availabilitySummary} 
                            isPreorder={this.state.isPreorder} selectedProduct={this.state.selectedProduct} marketCat={this.state.marketCat} 
                            onClick={this.handleProductClick} showSetupFee={this.props.showSetupFee} />
                    }
                    <div className="block">
                        <SelectedProduct selectedProduct={this.state.selectedProduct} selectedRouterId={this.state.selectedRouterId} 
                            onClick={this.handleProductReset} showSetupFee={this.props.showSetupFee} />
                        {
                            (this.state.selectedProduct?.availableRouters !== undefined && this.state.selectedRouterId < 0 && this.showRouterSelection() === true)
                            && <AvailableRouters availableRouters={this.state.selectedProduct.availableRouters} onClick={this.handleRouterClick} 
                            routerDiscount={this.state.selectedProduct?.availableDiscount?.routerDiscountGbp}
                            discountExpiry = {this.state.selectedProduct?.availableDiscount?.expiryDate}
                            discountCode = {this.state.selectedProduct?.availableDiscount?.discountCode} />
                        }
                        { this.showRouterSelection() === true &&
                            <SelectedRouter selectedRouterId={this.state.selectedRouterId} selectedRouter={this.state.selectedRouter} 
                                onClick={this.handleRouterReset} routerDiscount={this.state.selectedProduct?.availableDiscount?.routerDiscountGbp}
                                discountExpiry = {this.state.selectedProduct?.availableDiscount?.expiryDate}
                                discountCode = {this.state.selectedProduct?.availableDiscount?.discountCode} />
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <WaitlistSignUp selectedAddress={this.props.selectedAddress} />
            );
        }
    }
}